* {
    margin: 0;
    padding: 0;
}

body {
    background-color: rgb(0, 0, 0);
}

.scroller::-webkit-scrollbar {
    width: 10px;
}

.scroller::-webkit-scrollbar-track {
    border: solid 1px #323232;
    border-radius: 10px;
    background-color: #131515;
}

.scroller::-webkit-scrollbar-thumb {
    background: rgb(50, 50, 50);
    border: solid 1px #323232;
    border-radius: 10px;
}

.scroller::-webkit-scrollbar-thumb:hover {
    background: rgb(50, 50, 50);
}


.MuiDataGrid-cell:focus {
    outline: 'none' !important;
}

@keyframes fade {

    0%,
    100% {
        opacity: .25
    }

    50% {
        opacity: 1
    }
}

body {
    margin: 0;
    font-family: "monospace";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(0, 0, 0);
  }
  
  code {
    font-family: "monospace" !important;
  }
  