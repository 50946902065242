.navbar-dark {
  background-color: black !important;
}

.video-js .vjs-big-play-button {
  display: none !important;
}

.video-js .vjs-control-bar {
  display: flex;
}

.vjs-control-bar {
  background-color: black !important;
}

.vjs-poster {
  cursor: default !important;
}

.user-dropdown .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.user-dropdown .dropdown-toggle::after {
  display: none !important;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
}

.sidenav-button:focus {
  box-shadow: 0 0 0 0.2rem grey !important;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}
.App {
  text-align: center;
}

body {
  font-family: monospace !important;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar-dark {
  background-color: black !important;
}

.sidenav {
  height: 100%;
  width: 0;
  /* position: fixed; */
  z-index: 1;
  top: 0;
  background: rgba(20, 20, 20, .9);
  color: rgba(255, 255, 255, 1);
  overflow: hidden;
  padding-top: 60px;
}

.sidenav .form-control {
  border: black;
  background: rgba(255, 255, 255, 0.2);
  color: white;
}

.closebtn {
  font-size: 36px;
  z-index: 1000;
  border-radius: 10px;
}

#main {
  transition: margin-left .6s;
  padding: 20px;
}

.sidenav .nav-link {
  color: white;
  background-color: transparent;
}

.layer-item.selected {
  background-color: darkgrey;
}

#audio-controller:hover .audio-controller-toggle {
  display: block !important;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.component-tree {
  list-style: "none";
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul {
  list-style: "none";
  list-style-type: none;
  padding-inline-start: 10px;
}

.scroller::-webkit-scrollbar {
  width: 20px;
}

.scroller::-webkit-scrollbar-track {
  border: solid 1px #323232;
  border-radius: 10px;
  background-color: #131515;
}

.scroller::-webkit-scrollbar-thumb {
  background: rgb(50, 50, 50);
  border: solid 1px #323232;
  border-radius: 10px;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: rgb(50, 50, 50);
}

.dropdown-menu {
  background-color: #303030;
}

.dropdown-item {
  color: white;
}
.dropdown-item:hover {
  color: white;
  background-color: #404040;
}

#asset-viewer-3d {
  outline: none;
}

.shepherd-content {
  background-color: #111 !important;
  color: #fff !important;
}

.shepherd-button {
  color: #fff !important;
  border-color: #fff !important;
}

.shepherd-button:hover {
  color: #111 !important;
  background-color: #fff !important;
}

.shepherd-text {
  color: white !important;
}

@keyframes fadeinout {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
    ;
  }

  100% {
    opacity: 1;
  }
}

.nav-link.active {
  color:#FFF !important;
  background-color: rgb(80, 80, 80) !important;
}
